.PlayerBoardItem{
    width: 7.5em;
    height: 6em;
    background-color: #73C373;
    margin-bottom: 20px;
    /* padding: 5px; */
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 3px;
    overflow: clip;
}

.PlayerBoardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.PlayerBoardItemContainer {
    position: relative;
    /* margin: 10px; */
}

.exclamation {
    position: absolute;
    top: 0.2em; /* Adjust as needed */
    right: 0.55em; /* Adjust as needed */
    /* background-color: red; */
    color: #242424;
    /* color: white; */
    /* border-radius: 50%; */
    /* padding: 5px 10px; */
    /* border: 1px solid #000; */
    font-weight: bolder;
    font-size: 18px;
}

.PlayerBoardItem h2{
    margin-top: 10px;
    /* font-weight: lighter; */
    color: white;
    line-height: 80%;
}

.PlayerBoardItem p{
    line-height: 50%;
    font-weight: regular;
    color: white;
}

.PlayerBoardContainer {
    
}
