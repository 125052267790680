@import url('https://fonts.googleapis.com/css2?family=Segoe UI:wght@100..900&display=swap');

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #171717;
  /* background-image: url("../public/bg.jpg");

  background-image: 
    linear-gradient(
      rgba(0, 0, 0, 0.95), 
      rgba(0, 0, 0, 0.95)
    ),
    url("../public/bg.jpg"); */

    /* background-color: #171717;
    opacity: 1;
    background-image:  linear-gradient(
      rgba(23, 23, 23, 0.8), 
      rgba(23, 23, 23, 0.8)
    ), repeating-radial-gradient( circle at 0 0, transparent 0, #171717 40px ), repeating-linear-gradient( #22222255, #222222 ); */

    background-color: #171717 !important;
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='70' viewBox='0 0 56 98'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.01' fill-rule='nonzero'%3E%3Cpath d='M27.98 18.5l26 15v30l-26 15L1 63.5v-30l26-15zM6 35.8v25.4l21.98 12.68 22-12.69V35.8l-22-12.68L6 35.8zM0 30l25.96-15V0h-4v12.69L0 25.38v4.62zm0 37L25.96 82v16h-4v-13.69L0 71.62v-4.62zM30 0v15l25.98 15H56v-4.62h-.02L34 12.69V0h-4zm0 98v-16l25.98-15H56v4.62h-.02L34 85.31V98h-4z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Segoe UI', sans-serif;
}

p, b, h1, h2, h3 {
  font-family: 'Segoe UI', sans-serif;
}

input {
  font-size: 20px;
  padding-left: 2px;
  border: 1px solid #555;
  border-radius: 0px;
  outline: none;
  transition: 0.5s;
  height: 25px;
}
input[type=text]:focus {
  border: 1px solid #777;
}
button{
  cursor: pointer;
}

.version-number {
  color: #BCBCBC;
  font-size: 10px;
  position: absolute;
  bottom: 0px;
  right: 10px;
}

.website-link {
  cursor: pointer;
  text-decoration: none;
  /* color: #000000; */
  font-weight: 600;
  /* background-color: #F2F2F2; */
  border-radius: 5px;
  /* padding: 5px; */
  transition-duration: 0.3s;
}
.website-link:hover{
  color: #FFFFFF;
  /* background-color: #2B2B2B; */
}

.createGameContainer, .joinGameContainer {
  position: absolute;
  top:20VH;
  margin-left: 12%;
  width: 76%;
}

.footer{
  position: absolute;
  width: 90VW;
  margin-left: 5VW;
  bottom: 20px;
}

.homeContainer{
  /* position: absolute; */
  width: 50%;
  margin: auto;
  /* margin-left: 5VW; */
  /* top: 8VH; */
}

.homeModalContainer {
  margin: auto;
  margin-top: 10px;
  display: block;
  width: 54px;
}

.homeContainer img{
  /* width: 100px;
  height: 100px;
  margin-top: 40px; */
  margin-bottom: 20px;
}

.home{
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 100px;
  border-radius: 2px;
  font-family: 'Segoe UI', sans-serif;
  background-color: #ac2c2c;
  border: none;
  color: white;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

.RoomCode {
  /* background-color: #F2F2F2; */
  /* border: 2px solid #707070; */
  background-color: #3f3f3f;
  border: 1px solid #444;
  border-radius: 5px;
  margin: 5px;
  margin-top: 10px;
  padding: 5px;
  cursor: pointer;
}

.createButton, .joinButton {
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 100px;
  border-radius: 2px;
  font-family: 'Segoe UI', sans-serif;
  background-color: #ac2c2c;
  border: none;
  color: white;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

.createButton:disabled, .joinButton:disabled{
  background-color: #2B2B2B;
}

.startGameButton{
  /* min-width: 100px; */
  /* height: 30px; */
  border-radius: 2px;
  font-family: 'Segoe UI', sans-serif;
  background-color: #349e2b;
  border: none;
  color: white;
  margin: 20px;
  margin-bottom: 0px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}

.readyUnitContainer {
  width: 205px;
  text-align: center;
  margin: 0 auto;
}

.readyUnit {
  /* min-width: 150px;
  max-width: 200px; */
  width: 200px;
  border-radius: 2px;
  font-family: 'Segoe UI', sans-serif;
  background-color: #2d912d;
  border: none;
  padding-left: 8px;
  padding-right: 8px;
  
  color: white;
  text-align: left;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}
.readyUnit b{
  text-align: right;
}

.readyUnit p, .readyUnit b{
  color: #ffffff
}