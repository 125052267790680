@import url('https://fonts.googleapis.com/css2?family=Segoe UI:wght@100..900&display=swap');

@font-face {
  font-family: 'Segoe UI';
  src: url('SEGOEUI.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('SEGOEUIB.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('SEGOEUII.TTF') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('SEGOEUIZ.TTF') format('truetype');
  font-weight: bold;
  font-style: italic;
}

.PlayerInfo {
    text-align: left;
    position: absolute;
    left: 10px;
    top: 10px;
    border: 2px solid #707070;
    border-radius: 5px;
    line-height: 40%;
    width: fit-content;
    padding-left: 10px;
    padding-right: 20px;
}

.Rules, .CheatSheet {
  cursor: pointer;
  text-align: left;
  position: absolute;
  left: 2vw;
  top: 1vh;
  /* border: 2px solid #707070; */
  border-radius: 2px;
  line-height: 40%;
  width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #3f3f3f;
  border: 1px solid #444;
  display: flex;
  align-items: center;
  color: #fff;
}

.PlayerInfo {
  text-align: left;
  position: absolute;
  left: 10px;
  top: 10px;
  border: 2px solid #707070;
  border-radius: 5px;
  line-height: 40%;
  width: fit-content;
  padding-left: 10px;
  padding-right: 20px;
}

.logo {
  text-align: left;
  position: absolute;
  /* border: 2px solid #707070; */
  /* border-radius: 5px; */
  line-height: 40%;
  width: 200px;
  padding-left: 100px;
  padding-right: 20px;

}

img.PlayerInfo {
  display: none;
  width: 200px;
  padding: 0px;
  border: none !important;
  padding-bottom: 20px;
}

.PlayerInfo {
  display: none;
}

.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center; /* Center text within the container */
}


.money {
  color: #ffe600;
}

.life {
  color: #ff0000;
}

.HomeRules {
  cursor: pointer;
  text-align: center;
  /* border: 2px solid #707070; */
  border-radius: 5px;
  line-height: 40%;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #F2F2F2;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.CheatSheet {
  top: 5vh;
}

.CheatSheetModal {
  outline: none;
  position: absolute;
  top: 100px;
  left: 5px;
  right: 5px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right:5px;
  background-color: #FFFFFF;
}

.CheatSheetContainer img {
    margin-top: 5px;
    width: 100%;
    height: auto;
}

.Rules p, .HomeRules p, .CheatSheet p{
  font-size: 12px;
  margin: 8px;
  /* margin: 0; */
  /* padding-bottom: 5px; */
  margin-left: 0px;
  margin-right: 2px;
  display: inline-block;
}

.RulesContent h1, h3 {
  line-height: 5px;
}

.ReactModal__Overlay, .CloseModalButton {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  background-color: #171717 !important;
  border: none !important;
}

.RulesContainer {
  /* background: */
}

.RulesContent h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.RulesContent h3 {
  text-decoration: underline;
}

.RulesContainer {
  overflow: hidden;
  /* padding-bottom: 10px; */
  /* height: 100%; */
}

.RulesContent {
  /* line-height: 12px; */
  /* height: fit-content; */
  overflow-y: hidden;
}

.InfoIcon {
  /* margin: 5 auto; */
  /* margin-top: 5px; */
  display: inline-block;
}

.CloseModalButtonContainer {
}

.CloseModalButton {
  top: 10px;
  left: 10px;
  padding: 0;
  background-color: #FFFFFF;
  border: 0px;
}

.cls-1, .cls-4 {
  fill: none;
}

.cls-1 {
  stroke: #2c2c2c;
}

.cls-2 {
  fill: #2c2c2c;
  font-size: 16px;
  font-family: 'Segoe UI', sans-serif;
}

.cls-3 {
  stroke: none;
}


.cls-5, .cls-8 {
  fill: none;
}

.cls-5 {
  stroke: #707070;
}

.cls-6 {
  fill: #767676;
  font-size: 16px;
  font-family: 'Segoe UI', sans-serif;
}

.cls-7 {
  stroke: none;
}

.InfluenceSection {
    /* background-color: #F2F2F2; */
    border-radius: 5px;
    margin-bottom: 10px;
    /* width: 300px; */
    display: inline-block;
}

.InfluenceSection p{
    text-align: center;
}

.InfluenceSection h3{
    text-align: center;
}

.InfluenceUnitContainer {
    /* width: 110px; */
    text-align: center;
    /* margin-left: 15px; */
    /* margin-right: px; */
    line-height: 30%;
    display: inline-block;
}

.CurrentPlayer {
    text-align: right;
    position: absolute;
    right: 15px;
    top: 5px;
}

.EventLogContainer {
    /* border-right: 2px solid #707070; */
    /* border-left: 0px; */
    /* padding-right: 5px; */
    padding-left: 5px;
    position: absolute;
    font-size: 12px;
    right: 15px;
    top: 60px;
    text-align: right;
    line-height: 40%;
}
.EventLogBody {
    overflow-y: scroll;
    height: 9vh;
    padding-right: 3px;
}
.EventLogTitle {
    margin: 0;
    margin-bottom: 10px;
}
.bold {
    font-weight: bold;
}
.new {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}

.GameHeader {
    position: relative;
    height: 19VH;
}

.GameContainer {
    text-align: center;
}

.circle {
    height: 30px;
    width: 30px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
.DecisionsSection button {
    /* width: 9em; */
    min-width: 8em;
    height: 30px;
    border-radius: 2px;
    font-size: 1em;
    font-weight: 500;
    font-family: 'Segoe UI', sans-serif;
    background-color: #BCBCBC;
    border: none;
    color: #000;
    margin: 0.2em;
    margin-bottom: 0px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    /* font-size: 12px; */
}

.DecisionsSection p {
    text-align: center;
    margin-left: 0;
    /* margin: 0; */
    /* margin-bottom: 10px; */

}

table th {
  padding-right: 0.6em;
}

.DecisionsSection button#assassin {
  color: #FFF !important;
}
#captain {
  background: rgb(83,202,245);
  /* background: -moz-linear-gradient(180deg, rgba(83,202,245,1) 18%, rgba(75,150,177,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(83,202,245,1) 18%, rgba(75,150,177,1) 100%);
  background: linear-gradient(180deg, rgba(83,202,245,1) 18%, rgba(75,150,177,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#53caf5",endColorstr="#4b96b1",GradientType=1);
  border: 1px solid #74d8fc; */
}
#duke {
  background: rgb(240, 84, 243);
  /* background: -moz-linear-gradient(180deg, rgba(240, 84, 243,1) 18%, rgba(177,76,179,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(240, 84, 243,1) 18%, rgba(177,76,179,1) 100%);
  background: linear-gradient(180deg, rgba(240, 84, 243,1) 18%, rgba(177,76,179,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d949db",endColorstr="#b14cb3",GradientType=1);
  border: 1px solid #fd71ff; */
}
#assassin {
  background: rgb(92,87,117);
  /* background: -moz-linear-gradient(180deg, rgba(92,87,117,1) 18%, rgba(58,56,69,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(92,87,117,1) 18%, rgba(58,56,69,1) 100%);
  background: linear-gradient(180deg, rgba(92,87,117,1) 18%, rgba(58,56,69,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5c5775",endColorstr="#3a3845",GradientType=1);
  border: 1px solid #646064; */
}
#contessa {
    background-color: #e31f26;
}
#ambassador {
  background: rgb(192,215,47);
  /* background: -moz-linear-gradient(180deg, rgba(192,215,47,1) 18%, rgba(150,164,56,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(192,215,47,1) 18%, rgba(150,164,56,1) 100%);
  background: linear-gradient(180deg, rgba(192,215,47,1) 18%, rgba(150,164,56,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c0d72f",endColorstr="#96a438",GradientType=1);
  border: 1px solid #e7ff4d; */
}
#captain-color {
  color: #53caf5;
}
#duke-color {
  color: #d949db;
}
#assassin-color {
  color: #5c5775;
}
#contessa-color {
  color: #e31f26;
}
#ambassador-color {
  color: #c0d72f;
}
.DecisionTitle {
    text-align: left;
    margin-left: 22px;
}

table td {
  padding-bottom: 5px;
  
}

table button {
  margin: 2px 2px 2px 2px !important;
}

.DecisionButtonsContainer  {
  text-align: center;
  /* width: 80%; */
  /* margin-left: 30%; */
  margin: auto;
  width: 80%;
}

table {
  text-align: center;
}

th {
  display: none;
}

.Rules, .CheatSheet {
  margin: 20px 0px;
}

@media only screen and (min-width: 800) {
  .Rules {
    left: 10VW !important;
    top: 5.4VH !important;
    
  }
  .CheatSheet{
    left: 10VW !important;
    top: 9.8VH !important;

  }
}

@media only screen and (min-width: 1024px) {
  /* img.PlayerInfo {
    display: block !important;
  } */
  table {
    text-align: left;
  }
  th {
    display: inline-block;
  }
    .ReactModal__Content {
      width: 60%;
      margin: auto;
      padding-left: 0px;
      padding-right: 0px;
    }
    /* .PlayerInfo {
      left: 10VW;
      top: 5VH;
    } */
    .CurrentPlayer {
        text-align: right;
        position: absolute;
        right: 10VW;
        top: 5VH;
    }
    .logo {
      left: 2.5VW;
      top: 3VH;
    }
    
    .CheatSheetModal {
      position: absolute;
      top: 40px;
      left: 40px;
      right: 40px;
      background-color: #FFFFFF;
    }
    .EventLogContainer {
        text-align: right;
        position: absolute;
        right: 10VW;
        top: 12VH;
    }
    .GameHeader {
        height: 13VH;
    }
    /* .DecisionTitle {
        margin-left: 10VW;
    } */
    .DecisionButtonsContainer  {
        text-align: center;
        width: 80%;
        margin-left: 10%;
    }
  }